const makeLegalSideBar = (data) => {

  let arr = []
  data?.relationships?.fieldArticleContent?.forEach((sideBarData, index) => {
    arr.push({
      title: sideBarData?.title,
      id: `id${sideBarData?.nid}`,
      content: sideBarData?.relationships?.fieldArticleSectionLinks,
      qa_schema: false
    })
  })
  return arr;
}
export default makeLegalSideBar;