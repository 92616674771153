import { useStaticQuery, graphql } from "gatsby";
export default function useLegalData() {
  const data = useStaticQuery(graphql`
  {
    allSitePage(
      filter: {componentChunkName: {eq: "component---src-templates-legal-page-template-js"}}
    ) {
      totalCount
      nodes {
        context {
          fieldOverviewPage
          fieldTitle
          fieldBody
          fieldLastUpdate
          entityUrl {
            path
          }
          fieldIcon {
            iconName
            style
          }
        }
      }
    }
  }`)

  let result = data?.allSitePage?.nodes;

  return result;
}
