import React, { useEffect, useState } from 'react'
// import LegalPageHero from "src/components/hero/LegalPageHero";
import makeLegalSideBar from "src/components/helpers/makeLegalSideBar";
import getContentConnectorFields, { getContentConnectorAnchors } from "src/components/helpers/getContentConnectorFields";
import CirclUp from "../../static/circle-up-solid.svg";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import useLegalData from "src/hooks/useLegalData";
import loadable from '@loadable/component'
import "src/scss/legalPage/_legalPage.scss";
import { LegalPageHero, LegalHubTab } from "src/components/templateComponents"
import { getPageDataJsonPath } from "src/utils";
const LegalHubCards = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.LegalHubCards
});
import { SEO,LocalizedLink } from 'src/components/commonComponents';
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
const ContentConnector = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ContentConnector
 });
 const ScrollLinks = loadable(() => import('src/components/commonComponents'), {resolveComponent: (components) => components.SecondaryNavbar});
const Accordion = loadable(() => import("src/components/commonComponents"), {resolveComponent: (components) => components.Accordion});


const LegalPageTemplate = ({ pageContext, path }) => {
  const locale = pageContext.locale || "en";
  const [loadVisualPing, setLoad] = useState(false)
  let sideBarData = makeLegalSideBar(pageContext?.relationships?.fieldInThisArticleSidebar);
  let contentConnectors = [];
  if (Array.isArray(pageContext?.relationships?.fieldContentSection)) {
    contentConnectors = getContentConnectorFields(
      pageContext?.relationships?.fieldContentSection,
      "basicPage"
    );
  }
  const heroImage = pageContext?.heroImage
  const anchors = getContentConnectorAnchors(contentConnectors);
  const data = useLegalData()?.filter(d => !d?.context?.fieldOverviewPage);
  const OGMarkup = getOGMarkup(pageContext.title, 'legal page', { gatsbyImage: heroImage?.shareImage });
  const { entityMetatags: metaTags } = pageContext;
  const tabs = pageContext?.relationships?.fieldTabs
  const fieldContactInfo = pageContext?.relationships?.fieldContactInfo?.[0]

  let hreflangAlternatives = []
  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });

  hreflangAlternatives?.find(trans => {
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if(hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
  })

  useEffect(() => {
    if(pageContext?.entityUrl?.path === "/subprocessor-list"){
      let script=null;
      try{
        setTimeout(() => {
          script = document.createElement('script');
          script.async = true;
          script.src = 'https://visualping.io/externalfiles/widget/vp.min.js';
          document.querySelector('head').appendChild(script);
          setLoad(true);
          return () => {
            if(script){
              script.parentNode.removeChild( script );
            }
          }
        },2000)
      }catch(e){
        console.log("error in applying visual ping", e)
      }
    }
  },[])
  const pageDataPath = getPageDataJsonPath(pageContext?.entityUrl?.path)
  return (
    <div className="legal-page tw-legal-page tw-mb-[52px] lg:tw-mb-[76px] lg:tw-mt-[-0.7rem]">
      <SEO
        lang={locale}
        pageUrl={pageContext?.entityUrl?.path}
        title={pageContext?.title}
        meta={metaTags}
        OGMarkup={OGMarkup}
        alternatives={hreflangAlternatives}
        heroImage={pageContext?.fieldHideHeroImage ? undefined : heroImage}
        preloads={[pageDataPath]}
      />
      <LegalPageHero
        title={pageContext?.title}
        fieldHeroDescription={pageContext?.fieldHeroDescription}
        fieldPreHeading={pageContext?.fieldPreHeading}
        fieldOverviewPage={pageContext?.fieldOverviewPage}
        image={heroImage}
        fieldLastUpdate={pageContext?.fieldLastUpdate}
        fieldEffectiveDate={pageContext?.fieldEffectiveDate}
        fieldHideHeroImage={pageContext?.fieldHideHeroImage}
        showViewAll={pageContext?.fieldMakeAnchor}
      />
      <div className="container content-visibility-auto-mobile">
        {!!tabs?.length && (
          <div className="section-container tw-section-container tw-mt-[52px] lg:!tw-mt-[64px] legal-tab-section tw-legal-tab-section md:tw-mt-12">
            {" "}
            <LegalHubTab data={tabs} />{" "}
          </div>
        )}
        {!!fieldContactInfo && (
          <div className="section-container tw-section-container tw-mt-[52px] md:!tw-mt-[76px] contact-info-container tw-contact-info-container tw-ml-4 tw-mr-4">
            <h3 className="title-4">{fieldContactInfo?.field_cardTitle}</h3>
            <div
              className="contact-info-desc tw-contact-info-desc subtitle-2 tw-subtitle-2 dangerously-html-handle tw-mt-2 tw-text-left tw-text-2xl tw-leading-6 tw-text-gray-700 lg:tw-w-1/2 tw-font-medium
          md:tw-w-full md:tw-text-base sm:tw-w-full sm:tw-text-[18px]"
              dangerouslySetInnerHTML={{
                __html: fieldContactInfo?.fieldCardContent?.value,
              }}
            ></div>
          </div>
        )}
      </div>
      {!pageContext?.fieldOverviewPage && (
        <div className="container">
          {pageContext?.fieldListOfAllLegalPages && (
            <div className="main-page-cards tw-main-page-cards tw-h-0 tw-pt-0 tw-pb-0 lg:tw-pl-4 lg:tw-pr-4">
              <LegalHubCards
                data={data}
                path={pageContext?.entityUrl?.path}
                mainPage={true}
              />
            </div>
          )}
          <div
            className="main-page-columns tw-main-page-columns tw-flex tw-flex-col-reverse md:tw-flex-row tw-m-0 md:-tw-m-3"
            id="content-connector"
          >
            <div
              className={`tw-block tw-p-3 lg:tw-flex-none tw-w-full ${
                pageContext?.relationships?.fieldInThisArticleSidebar
                  ? "tw-w-full md:tw-w-3/4 tw-flex-none"
                  : "tw-flex-nonetw-w-full"
              }`}
            >
              {pageContext?.body?.value && (
                <div
                  className="section-container tw-section-container section lg:!tw-p-0 lg:!tw-py-0 lg:!tw-px-4 !tw-py-0 tw-pt-0 tw-pb-0 tw-text-[16px] md:tw-text-[18px] tw-text-accruent_black_olive legal-main-body tw-legal-main-body 
                    tw-mb-[52px] md:tw-mb-0 md:tw-mt-[76px] tw-mt-4 dangerously-html-handle"
                  dangerouslySetInnerHTML={{ __html: pageContext?.body.value }}
                ></div>
              )}
              {!!anchors.length && (
                <ScrollLinks
                  title={pageContext.fieldTermJumpLinksHeader}
                  anchors={anchors}
                  offset={-185}
                />
              )}
              <div className="tw-left-legal-container">
                {contentConnectors.map((data, index) => (
                  <div
                    key={index}
                    className="section-container tw-section-container tw-mt-[52px] md:!tw-mt-[76px] md:tw-pl-4 md:tw-pr-4 tw-pt-0 tw-pb-0 legal-container tw-legal-container"
                  >
                    <ContentConnector
                      key={index}
                      data={data}
                      pageType={pageContext.type?.id}
                      locale={locale}
                    />
                  </div>
                ))}
              </div>
              {(pageContext?.entityUrl?.path === "/cookies" ||
                pageContext?.entityUrl?.path === "/cookie" ||
                pageContext?.entityUrl?.path === "/cookie-list") && (
                <div className="section-container tw-mt-[52px] lg:!tw-mt-[76px] section lg:tw-pl-4 lg:tw-pr-4 tw-pt-0 tw-pb-0">
                  {/* <!-- OneTrust Cookies List start --> */}
                  <div id="ot-sdk-cookie-policy">&nbsp;</div>
                  {/* <!-- OneTrust Cookies List end --> */}
                </div>
              )}
              {loadVisualPing && (
                <div
                  className="visual-ping tw-flex"
                  id="visualping_widget"
                  data-url="aHR0cHM6Ly93d3cuYWNjcnVlbnQuY29tL3N1YnByb2Nlc3Nvci1saXN0"
                  data-mode="medium"
                  data-bg="transparent"
                  data-btn="#F22735,#ffffff"
                  data-trigger="1"
                  data-compare="visual"
                  data-crop="60_400_900_3300"
                ></div>
              )}
              {pageContext?.relationships?.fieldInThisArticleSidebar &&
                pageContext?.fieldShowMap && (
                  <div className="section-container tw-mt-[52px] md:tw-mt-[76px] secondary-content">
                    <div className="icon-style tw-mb-7 tw-flex tw-justify-center">
                      <LocalizedLink
                        to={`${pageContext?.entityUrl?.path}/${
                          pageContext?.fieldShowMap
                            ? "#table-of-contents"
                            : "#content-connector"
                        }`}
                      >
                        <button
                          className="scroll-btn md:tw-hidden tw-flex tw-bg-accruent_sapphire tw-border-none tw-text-white tw-rounded-[23px] tw-px-8 
                      tw-py-2.5 tw-mr-2 tw-text-[16px] tw-font-roboto tw-cursor-pointer"
                        >
                          Table of Contents
                        </button>
                      </LocalizedLink>
                      <LocalizedLink
                        to={`${pageContext?.entityUrl?.path}/${
                          pageContext?.fieldShowMap
                            ? "#table-of-contents"
                            : "#content-connector"
                        }`}
                      >
                        <img
                          src={CirclUp}
                          alt="circup"
                          decoding="async"
                          className="tw-w-10 tw-h-10"
                        />
                      </LocalizedLink>
                    </div>
                  </div>
                )}
            </div>
            {pageContext?.relationships?.fieldInThisArticleSidebar &&
              pageContext?.fieldShowMap && (
                <div className="tw-block tw-p-3 tw-flex-none tw-w-full md:tw-w-1/4">
                  <div
                    className="section-container tw-mt-[52px] lg:!tw-mt-[76px] tw-pt-0 tw-pb-0 legal-accordian tw-p-0 tw-bg-accruent_lightgray md:tw-bg-transparent"
                    id="table-of-contents"
                  >
                    <StickyBox
                      scrollContainerClass={"right-accordian-box"}
                      leftContentClass={"left-legal-container"}
                      secondaryContentClass={"footer-wrapper"}
                    >
                      <div className="right-accordian-box tw-overflow-y-auto tw-max-h-[650px]">
                        <h6 className="tw-text-[26px] md:tw-text-base tw-font-Poppins tw-font-bold tw-text-accruent_heading_color tw-p-4 md:tw-p-0 tw-mb-0 md:tw-mb-4">
                          {
                            pageContext?.relationships
                              ?.fieldInThisArticleSidebar?.title
                          }
                        </h6>
                        <Accordion data={sideBarData} isLegal={true} />
                      </div>
                    </StickyBox>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
}
export default LegalPageTemplate;